import { Button, ButtonGroup, Container, Stack } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router"
import CircularProgress from "src/components/circular-progress"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { getQuote } from "src/redux/slices/quotes"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import "./base.css"

import { Permissions } from "src/@types/security"

import ReactToPrint from "react-to-print"
import { useAuthContext } from "src/auth/useAuthContext"
import QuoteTermsAndConditions from "./terms-and-conditions"
import QuoteJHA from "./jha"
import QuoteTables from "./quote"

export default function QuoteViewPage() {
    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)

    const navigate = useNavigate()

    const { hasPermission } = useAuthContext()

    const [isLoading, setIsLoading] = useState(true)

    const dispatch = useDispatch()

    const componentRef = useRef<any>(null)

    useEffect(() => {
        const getQuoteAndLineItemsAsync = async () => {
            if (idAsNumber) {
                await dispatch(getQuote(idAsNumber))
                setIsLoading(false)
            }
        }
        getQuoteAndLineItemsAsync()
    }, [dispatch, idAsNumber])

    const quote = useSelector((state) => state.quotes.quotes.find((item) => item.id === idAsNumber))

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (quote === undefined) {
        return <div>Not found</div>
    }

    return (
        <>
            <Helmet>
                <title>{`Quote #${quote?.sf_estimate?.number || ""}`}</title>
            </Helmet>
            <Container className="printable-fullwidth">
                <CustomBreadcrumbs
                    sx={{
                        mb: 0,
                    }}
                    heading="View Quote"
                    links={[
                        // {
                        //     name: "Quotes",
                        // },
                        {
                            name: "Quotes",
                            href: PATHS.quotes.quotes.list(),
                        },
                        {
                            name: "View",
                        },
                    ]}
                    action={
                        <ButtonGroup variant="contained" size="small">
                            <Button
                                onClick={() => {
                                    navigate(PATHS.quotes.quotes.details(idAsNumber))
                                }}
                            >
                                Edit
                            </Button>
                            <Button
                                // onClick={() => {
                                //     navigate(PATHS.quotes.quotes.details(idAsNumber))
                                // }}
                                disabled
                            >
                                View
                            </Button>
                            {hasPermission(Permissions.QUOTE_EXPORT) && (
                                <Button
                                    onClick={() => {
                                        navigate(PATHS.quotes.quotes.export(idAsNumber))
                                    }}
                                >
                                    Export
                                </Button>
                            )}
                        </ButtonGroup>
                    }
                />
                <Stack direction="row" justifyContent="right" mb={3}>
                    <ButtonGroup variant="text" size="small">
                        {quote.related_quotes.length > 0 && (
                            <Button
                                onClick={() => {
                                    navigate(
                                        PATHS.quotes.quotes.printTwo(
                                            idAsNumber,
                                            quote.related_quotes[0].id
                                        )
                                    )
                                }}
                            >
                                View & Print All Related
                            </Button>
                        )}

                        <ReactToPrint
                            trigger={() => <Button>Print</Button>}
                            content={() => componentRef.current}
                        />
                    </ButtonGroup>
                </Stack>
                <div ref={componentRef}>
                    <QuoteTables quote={quote} />
                    <Stack
                        className={quote.data.print_template?.disable_jha ? "toc no-break" : "toc"}
                    >
                        <QuoteTermsAndConditions quote={quote} />
                    </Stack>
                    {!quote.data.print_template?.disable_jha && (
                        <Stack>
                            <QuoteJHA quote={quote} />
                        </Stack>
                    )}
                </div>
                {/* </Stack> */}
            </Container>
        </>
    )
}
