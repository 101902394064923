import { Button, CircularProgress, Container, Stack, Typography, ButtonGroup } from "@mui/material"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router"
import { QuoteUpdate } from "src/@types/quote"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import QuoteEditForm from "src/forms/quote-edit"
// import { getLineItemTypes } from "src/redux/slices/lineItemTypes"
// import { getCategories } from "src/redux/slices/categories"
import { getQuote, updateQuote } from "src/redux/slices/quotes"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
// import { getFeeTypes } from "src/redux/slices/fee-types"
import { Permissions } from "src/@types/security"
import { useAuthContext } from "src/auth/useAuthContext"
import { apiRecalculateMaterialsQuantity } from "src/api/quotes"
// import { getFinancingOptionTypes } from "src/redux/slices/financing-option-types"
import { apiCreateQuoteFromTemplate } from "src/api/quote-templates"
import QuoteContent from "./quote-content"

export default function QuoteDetailsPage() {
    const { id } = useParams<{ id: string }>()
    // @ts-ignore
    const idAsNumber = parseInt(id, 10)
    const { hasPermission } = useAuthContext()
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate()

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    // useEffect(() => {
    //     dispatch(getCategories())
    //     dispatch(getLineItemTypes())
    //     dispatch(getFeeTypes())
    //     dispatch(getFinancingOptionTypes())
    // }, [dispatch])

    useEffect(() => {
        const getQuoteAndLineItemsAsync = async () => {
            if (idAsNumber) {
                await dispatch(getQuote(idAsNumber))
                setIsLoading(false)
            }
        }
        getQuoteAndLineItemsAsync()
    }, [dispatch, idAsNumber])

    const quote = useSelector((state) => state.quotes.quotes.find((item) => item.id === idAsNumber))

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (quote === undefined) {
        return <div>Not found</div>
    }

    const onCreateRelatedQuote = async (template_id: number) => {
        setIsLoading(true)
        const response = await apiCreateQuoteFromTemplate(template_id, {
            related_quote_id: quote.id,
            estimate_id: quote.sf_estimate?.id || null,
        })
        if (response.status === 201) {
            enqueueSnackbar("Quote created successfully", { variant: "success" })
            navigate(PATHS.quotes.quotes.details(response.data.id))
        } else {
            enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
        }
        setIsLoading(false)
    }

    const onSubmit = async (data: QuoteUpdate) => {
        try {
            const response = await dispatch(updateQuote(idAsNumber, data))
            if (response.status === 200) {
                enqueueSnackbar("Quote updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Helmet>
                <title>Update Quote</title>
            </Helmet>
            <Container>
                <CustomBreadcrumbs
                    heading="Update Quote"
                    links={[
                        // {
                        //     name: "Quotes",
                        // },
                        {
                            name: "Quotes",
                            href: PATHS.quotes.quotes.list(),
                        },
                        {
                            name: "Edit",
                        },
                    ]}
                    action={
                        <ButtonGroup variant="contained" size="small">
                            <Button
                                disabled
                                // onClick={() => {
                                //     navigate(PATHS.quotes.quotes.view(idAsNumber))
                                // }}
                            >
                                Edit
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate(PATHS.quotes.quotes.view(idAsNumber))
                                }}
                            >
                                View
                            </Button>
                            {hasPermission(Permissions.QUOTE_EXPORT) && (
                                <Button
                                    onClick={() => {
                                        navigate(PATHS.quotes.quotes.export(idAsNumber))
                                    }}
                                >
                                    Export
                                </Button>
                            )}
                        </ButtonGroup>
                    }
                />
                <QuoteEditForm onSubmit={onSubmit} initialData={quote} />
                {!quote.sf_estimate ? (
                    <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                        Please select an estimate before recording measurements.
                    </Typography>
                ) : (
                    <QuoteContent quote={quote} handleCreateRelatedQuote={onCreateRelatedQuote} />
                )}
                {hasPermission(Permissions.ADMIN) && (
                    <Stack direction="column">
                        <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                            Admin
                        </Typography>
                        <div>
                            <Button
                                onClick={async () => {
                                    apiRecalculateMaterialsQuantity(quote.id)
                                }}
                            >
                                Recalculate materials quantity
                            </Button>
                        </div>
                    </Stack>
                )}
            </Container>
        </>
    )
}
