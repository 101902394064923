import { TMeasurement } from "src/@types/measurement"
import { TMaterial } from "src/@types/material"
import { SyntheticEvent, useEffect, useRef, useState } from "react"
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Button,
    ButtonGroup,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material"
import { TLineItem } from "src/@types/lineItem"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { useDispatch } from "src/redux/store"
import { useSnackbar } from "notistack"
import {
    createMeasurement,
    deleteLineItem,
    deleteMaterialOption,
    updateLineItem,
    updateMaterial,
    updateMaterialOption,
} from "src/redux/slices/quotes"
import { TMaterialType } from "src/@types/material-type"
import { getLineItemName } from "src/services/line-item-service"
import ReactMarkdown from "react-markdown"
import { getMeasurementName } from "src/services/material-service"
import Measurement from "./measurement"
import EditLineItemDialog from "./edit-line-item-dialog"
import CustomFieldComponent from "../custom-field"

const updateCustomFieldValuesInData = (data: any, fieldId: string, values?: any, value?: any) => {
    if (!data.custom_fields) return data
    const newData = JSON.parse(JSON.stringify(data))
    if (value !== undefined) {
        newData.custom_fields[fieldId].value = value
    } else if (values !== undefined) {
        newData.custom_fields[fieldId].values = values
    }
    return newData
}

type Props = {
    lineItem: TLineItem
    expanded: boolean
    setExpanded: (value: number | null) => void
    displayOptions: (1 | 2 | 3)[]
    measurements: TMeasurement[]
    materials: TMaterial[]
}

export default function LineItemDetails({
    lineItem,
    expanded,
    setExpanded,
    displayOptions,
    measurements,
    materials,
}: Props) {
    const accordionRef = useRef<HTMLDivElement | null>(null)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (expanded && accordionRef.current) {
            setTimeout(() => {
                if (!accordionRef.current) return
                // accordionRef.current.scrollIntoView({
                //     behavior: "smooth",
                //     block: "start",
                // })
                const offset = 60 // Adjust this value to set your desired offset
                const elementPosition =
                    accordionRef.current.getBoundingClientRect().top + window.pageYOffset
                const offsetPosition = elementPosition - offset

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                })
                // const parentTop = accordionRef.current.offsetParent?.getBoundingClientRect().top || 0
                // let scrollTo = accordionRef.current.offsetTop + parentTop
                // console.log("scrollTo", scrollTo)
                // if (scrollTo < 0) scrollTo = 0
                // window.scrollTo({ top: scrollTo, behavior: "smooth" })
            }, 100)
        }
    }, [expanded])

    const [editedItem, setEditedItem] = useState<TLineItem | null>(null)
    const [location, setLocation] = useState<string>(lineItem.location || "")
    const [flatFee, setFlatFee] = useState<number>(lineItem.flat_fee || 0)
    useEffect(() => {
        setLocation(lineItem.location || "")
    }, [lineItem.location])

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    // const measurements = useSelector(
    //     (state) => state.quotes.measurementsByLineItemId[lineItem.id] || []
    // )
    // const materials = useSelector((state) => state.quotes.materialsByLineItemId[lineItem.id] || [])

    const onDelete = async () => {
        if (window.confirm("Are you sure you want to delete this Service?")) {
            const response = await dispatch(deleteLineItem(lineItem))
            if (response.status === 200) {
                enqueueSnackbar("Service deleted successfully", { variant: "success" })
            } else {
                enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
            }
        }
    }

    const onAddMeasurement = async (id?: number) => {
        setIsLoading(true)
        const measurementTypeId =
            id ||
            lineItem.type.measurements_default[0]?.id ||
            lineItem.type.measurements_possible[0]?.id ||
            null
        if (!measurementTypeId) {
            enqueueSnackbar("Cannot add a measurement", { variant: "error" })
            return
        }
        const response = await dispatch(createMeasurement(lineItem.id, measurementTypeId))
        if (response.status === 201) {
            enqueueSnackbar("Measurement added successfully", { variant: "success" })
        }
        setIsLoading(false)
    }

    const materialTypesById: {
        [key: number]: TMaterialType
    } = {}

    lineItem.materials_possible.forEach((materialType) => {
        materialTypesById[materialType.id] = materialType
    })

    const currentMaterialOptions: {
        [key: number]: TMaterialType | null
    } = {
        1: null,
        2: null,
        3: null,
    }
    let materialsNum = 0
    materials.forEach((material) => {
        currentMaterialOptions[material.option] = materialTypesById[material.type_id]
        materialsNum += 1
    })

    const onChangeMaterial = async (event: any, value: TMaterialType | null, option: number) => {
        if (!value) {
            const materialToDelete = materials.find((material) => material.option === option)
            if (!materialToDelete) return
            const response = await dispatch(deleteMaterialOption(materialToDelete))
            if (response.status === 204) {
                enqueueSnackbar("Material removed successfully", { variant: "success" })
            }
            return
        }
        const response = await dispatch(updateMaterialOption(lineItem.id, value.id, option))
        if (response.status === 200) {
            enqueueSnackbar("Material updated successfully", { variant: "success" })
        }
    }

    const onChangeLocation = async (event: any) => {
        const response = await dispatch(updateLineItem(lineItem.id, { location }))
        if (response.status === 200) {
            enqueueSnackbar("Location updated successfully", { variant: "success" })
        }
    }

    const onChangeFlatFee = async (event: any) => {
        const response = await dispatch(updateLineItem(lineItem.id, { flat_fee: flatFee }))
        console.log(response)
        if (response.status === 200) {
            enqueueSnackbar("Flat Fee updated successfully", { variant: "success" })
        }
        if (response.status === 400) {
            enqueueSnackbar(response.data.error, { variant: "error" })
            // Reset the value to the previous value
            setFlatFee(lineItem.flat_fee || 0)
        }
    }

    const measurementsPossible = lineItem.type.measurements_possible

    return (
        // <FormProvider methods={methods} onSubmit={() => { }}>
        <>
            <Accordion
                expanded={expanded}
                onChange={() => setExpanded(expanded ? null : lineItem.id)}
                ref={accordionRef}
                // sx={{ borderTop: "1px solid grey" }}
                TransitionProps={{ timeout: 0 }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{
                        // Display a different background color if the line item has a positive value and material option 1
                        backgroundColor:
                            (lineItem.value > 0 && materialsNum > 0) || lineItem.flat_fee
                                ? "#DDD"
                                : "white",
                        borderTop: "1px solid #BBB",
                        borderLeft: "1px solid #BBB",
                        borderRight: "1px solid #BBB",
                        borderBottom: expanded ? "" : "1px solid #BBB",
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={0}
                        justifyContent="space-between"
                        width="100%"
                        paddingRight={2}
                    >
                        <Typography>
                            <b>{getLineItemName(lineItem)}</b>
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <IconButton
                                size="small"
                                color="default"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setEditedItem(lineItem)
                                }}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                size="small"
                                color="default"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onDelete()
                                }}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Stack>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        borderLeft: "1px solid #BBB",
                        borderRight: "1px solid #BBB",
                    }}
                >
                    {/* <FormProvider methods={methods} onSubmit={() => { }}> */}
                    <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
                        {lineItem.data.notes && (
                            <ReactMarkdown>{lineItem.data.notes}</ReactMarkdown>
                        )}
                        <TextField
                            id="location"
                            label="Location"
                            // multiline
                            // rows={2}
                            fullWidth
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            onBlur={onChangeLocation}
                        />
                        {lineItem.data.allow_flat_fee && (
                            <TextField
                                id="flat-fee"
                                label="Flat Fee"
                                fullWidth
                                type="number"
                                inputProps={{ min: 0, step: 5, pattern: "[0-9]*" }}
                                InputProps={{ startAdornment: "$" }}
                                value={flatFee || ""}
                                onChange={(e) => setFlatFee(parseInt(e.target.value, 10))}
                                onBlur={onChangeFlatFee}
                            />
                        )}
                        {Object.keys(lineItem.data.custom_fields || {}).map((key: string) => {
                            const customFields = lineItem.data.custom_fields || {}
                            const field = customFields[key] || {}
                            if (!field.visible) return null
                            return (
                                <CustomFieldComponent
                                    key={key}
                                    field={field}
                                    // @ts-ignore
                                    handleSave={async (value) => {
                                        console.log(value)
                                        await dispatch(
                                            updateLineItem(lineItem.id, {
                                                data: updateCustomFieldValuesInData(
                                                    lineItem.data,
                                                    key,
                                                    value
                                                ),
                                            })
                                        )
                                    }}
                                />
                            )
                        })}
                        {materials.map((material) => {
                            if (!material.data.custom_fields) return null
                            if (!displayOptions.includes(material.option)) return null
                            return Object.keys(material.data.custom_fields).map((key: string) => {
                                const field = material.data.custom_fields[key]
                                if (!field.visible) return null
                                return (
                                    <CustomFieldComponent
                                        key={key}
                                        field={field}
                                        // @ts-ignore
                                        handleSave={async (values) => {
                                            await dispatch(
                                                updateMaterial(material.id, {
                                                    data: updateCustomFieldValuesInData(
                                                        material.data,
                                                        key,
                                                        values
                                                    ),
                                                })
                                            )
                                        }}
                                    />
                                )
                            })
                        })}
                        {lineItem.data.number_of_display_material_options > 0 && (
                            <>
                                <Divider color="disabled" />
                                <Typography variant="subtitle1">
                                    {lineItem.data.materials_title || "Materials"}
                                </Typography>
                                <Stack
                                    direction={{ sm: "column", md: "row" }}
                                    spacing={1}
                                    sx={{ mt: 2 }}
                                >
                                    <Autocomplete
                                        id="material-option-1"
                                        getOptionLabel={(option) =>
                                            option.display_name || option.name
                                        }
                                        onChange={(
                                            e: SyntheticEvent,
                                            value: TMaterialType | null
                                        ) => onChangeMaterial(e, value, 1)}
                                        disabled={lineItem.data.do_not_allow_change_materials}
                                        value={currentMaterialOptions[1] || null}
                                        options={lineItem.materials_possible}
                                        sx={{ width: "100%", marginBottom: 2 }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Option 1" />
                                        )}
                                    />
                                    {lineItem.data.number_of_display_material_options > 1 && (
                                        <Autocomplete
                                            id="material-option-2"
                                            getOptionLabel={(option) =>
                                                option.display_name || option.name
                                            }
                                            onChange={(
                                                e: SyntheticEvent,
                                                value: TMaterialType | null
                                            ) => onChangeMaterial(e, value, 2)}
                                            value={currentMaterialOptions[2] || null}
                                            options={lineItem.materials_possible}
                                            disabled={lineItem.data.do_not_allow_change_materials}
                                            sx={{ width: "100%", marginBottom: 2 }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Option 2" />
                                            )}
                                        />
                                    )}
                                    {lineItem.data.number_of_display_material_options > 2 && (
                                        <Autocomplete
                                            id="material-option-3"
                                            getOptionLabel={(option) =>
                                                option.display_name || option.name
                                            }
                                            onChange={(
                                                e: SyntheticEvent,
                                                value: TMaterialType | null
                                            ) => onChangeMaterial(e, value, 3)}
                                            value={currentMaterialOptions[3] || null}
                                            options={lineItem.materials_possible}
                                            disabled={lineItem.data.do_not_allow_change_materials}
                                            sx={{ width: "100%", marginBottom: 2 }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Option 3" />
                                            )}
                                        />
                                    )}
                                </Stack>
                            </>
                        )}
                        {/* <Divider color="disabled" /> */}
                        {measurements.map((measurement, i) => {
                            if (measurement.data.hidden) return null
                            return (
                                <Measurement
                                    key={`measurement.${measurement.id}`}
                                    measurement={measurement}
                                    pos={i}
                                    lineItemMeasurements={measurements}
                                />
                            )
                        })}
                    </Stack>
                    {measurements.length === 0 && measurementsPossible.length > 0 && (
                        <Stack direction="row" spacing={1} mt={1} alignItems="center">
                            <Typography>Choose a Measurement:</Typography>
                            <ButtonGroup
                                size="small"
                                color="primary"
                                variant="outlined"
                                disabled={isLoading}
                            >
                                {measurementsPossible.map((item) => (
                                    <Button
                                        key={item.id}
                                        onClick={(e) => {
                                            onAddMeasurement(item.id)
                                        }}
                                    >
                                        {getMeasurementName(item)}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Stack>
                    )}
                </AccordionDetails>
                <AccordionActions
                    sx={{
                        borderLeft: "1px solid #BBB",
                        borderRight: "1px solid #BBB",
                        borderRadius: "4px",
                        borderBottom: "1px solid #BBB",
                    }}
                >
                    {measurementsPossible.length > 0 && measurements.length > 0 && (
                        <>
                            <Button
                                size="small"
                                color="primary"
                                // onClick={onAddMeasurement}
                                onClick={
                                    measurementsPossible.length === 1
                                        ? () => onAddMeasurement()
                                        : handleOpenMenu
                                }
                                endIcon={
                                    measurementsPossible.length === 1 ? null : <ArrowDropDownIcon />
                                }
                            >
                                Add Measurement
                            </Button>
                            <Menu
                                id="create-quote-from-template-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleCloseMenu}
                            >
                                {measurementsPossible.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        onClick={(e) => {
                                            onAddMeasurement(item.id)
                                            handleCloseMenu()
                                        }}
                                        dense
                                    >
                                        {getMeasurementName(item)}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    )}
                    <Button size="small" color="error" onClick={onDelete}>
                        Delete Service
                    </Button>
                </AccordionActions>
            </Accordion>
            <EditLineItemDialog onClose={() => setEditedItem(null)} lineItem={editedItem} />
        </>

        // </FormProvider>
    )
}
