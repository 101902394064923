import { Quote } from "src/@types/quote"
import { SFEstimate } from "src/@types/sf-estimate"
import LogoTransparentHL from "./logo-transparent-hl.jpeg"
import LogoTransparentPW from "./logo-transparent-pw.jpeg"

import JHATermsHL from "./jha-hl.json"
import JHATermsPW from "./jha-pw.json"

const getEstimateAddress = (estimate: SFEstimate | null | undefined) => {
    if (!estimate || !estimate.street_1) return ""
    return `${estimate.street_1}, ${estimate.city}, ${estimate.state_prov} ${estimate.postal_code}`
}

type Props = {
    quote: Quote
}

const QuoteJHA = ({ quote }: Props) => {
    console.log("JHA")
    return (
        <div className="printable-only jha">
            <div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        marginBottom: 12,
                        marginTop: 10,
                        paddingTop: 20,
                        lineHeight: 1.2,
                        backgroundImage: `url(${
                            quote.category?.name.includes("PW")
                                ? LogoTransparentPW
                                : LogoTransparentHL
                        })`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                >
                    <b style={{ fontSize: 18 }}>Job Hazard Analysis</b>
                    <b style={{ fontSize: 14 }}>WiseGuys Pro-Wash</b>
                    <b style={{ fontSize: 14 }}>
                        <i>by ExpertSafetyServices.com</i>
                    </b>
                </div>
                <table style={{ width: "100%" }}>
                    <tr>
                        <td width="10%" style={{ whiteSpace: "nowrap" }}>
                            <b>Job Name:</b>
                        </td>
                        <td width="100%">{quote.sf_estimate?.customer.name}</td>

                        <td style={{ textAlign: "right", whiteSpace: "nowrap" }} width="10%">
                            <b>Filled By:</b>{" "}
                            {`${quote.created_by?.first_name} ${quote.created_by?.last_name}`}{" "}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ whiteSpace: "nowrap" }}>
                            <b>Job Number:</b>
                        </td>
                        <td>{quote.sf_estimate?.number}</td>

                        <td style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                            <b>Employee&apos;s Signature</b>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ whiteSpace: "nowrap" }}>
                            <b>Job Address:</b>
                        </td>
                        <td>{getEstimateAddress(quote?.sf_estimate)}</td>

                        <td
                            style={{
                                borderBottom: "1px solid black",
                                textAlign: "center",
                                fontFamily: "Euphoria Script",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {`${quote.created_by?.first_name} ${quote.created_by?.last_name}`}
                        </td>
                    </tr>
                </table>
                <table style={{ width: "100%", marginBottom: 6 }} className="jha-content">
                    <thead>
                        <tr>
                            <th>Sequence of Work</th>
                            <th>Potential Hazard</th>
                            <th>Method for Eliminating or Controlling</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(quote.category?.name.includes("PW") ? JHATermsPW : JHATermsHL).map(
                            (item, index) => (
                                <tr key={index}>
                                    {item.sequence_of_work && (
                                        <td rowSpan={item.span || 1}>{item.sequence_of_work}</td>
                                    )}

                                    <td>{item.potential_hazard}</td>
                                    <td>
                                        {item.method}
                                        {/* {item?.additional_information && (
                                                        <>
                                                            <br />
                                                            <i>{item?.additional_information}</i>
                                                        </>
                                                    )} */}
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
                <b>
                    The Job Hazard Analysis helps employers identify safety hazards before they
                    exist in order to reduce the risks both to themselves and to their customers
                </b>
            </div>
        </div>
    )
}

export default QuoteJHA
