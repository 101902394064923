import { Navigate, useRoutes } from "react-router-dom"
// import PayrollRunsList from "src/pages/payroll/runs/PayrollRunsList"
// import CreatePayrollRun from "src/pages/payroll/runs/CreatePayrollRun"
// import EditPayrollRun from "src/pages/payroll/runs/EditPayrollRun"
import JobsList from "src/pages/data/jobs/JobsList"
// import PayTypesList from "src/pages/payroll/payTypes/payTypesList"
// import EditPayTypeView from "src/pages/payroll/payTypes/edit"
// import CreatePayTypeView from "src/pages/payroll/payTypes/create"
// auth
import CategoriesList from "src/pages/quotes/categories/categories-list"
import CategoryCreate from "src/pages/quotes/categories/category-create"
import CategoryDetailsPage from "src/pages/quotes/categories/category-details"
import MaterialTypesList from "src/pages/quotes/material-types/material-types-list"
import MaterialTypeCreatePage from "src/pages/quotes/material-types/material-type-create"
import MaterialTypeDetailsPage from "src/pages/quotes/material-types/material-type-details"
import MeasurementTypesListPage from "src/pages/quotes/measurement-types/measurement-types-list"
import MeasurementTypeCreatePage from "src/pages/quotes/measurement-types/measurement-types-create"
import MeasurementTypeDetailsPage from "src/pages/quotes/measurement-types/measurement-type-details"
import LineItemTypesListPage from "src/pages/quotes/line-item-types/line-item-types-list"
import LineItemTypeCreatePage from "src/pages/quotes/line-item-types/line-item-type-create"
import LineItemTypeDetailsPage from "src/pages/quotes/line-item-types/line-item-type-details"
import QuotesListPage from "src/pages/quotes/quotes/quotes-list"
import QuotesCreatePage from "src/pages/quotes/quotes/quote-create"
import QuotesDetailsPage from "src/pages/quotes/quotes/quote-details"
import QuoteViewPage from "src/pages/quotes/quotes/quote-view"
import QuoteTemplatesListPage from "src/pages/quotes/quote-templates/quote-templates-list"
import QuoteTemplateCreatePage from "src/pages/quotes/quote-templates/quote-template-create"
import QuoteTemplateDetailsPage from "src/pages/quotes/quote-templates/quote-template-details"
import FeeTypesListPage from "src/pages/quotes/fee-types/fee-types-list"
import FeeTypeCreatePage from "src/pages/quotes/fee-types/fee-type-create"
import FeeTypeDetailsPage from "src/pages/quotes/fee-types/fee-type-details"
import CoefficientsListPage from "src/pages/quotes/coefficients/coefficient-containers-list"
import CoefficientCreatePage from "src/pages/quotes/coefficients/coefficient-container-create"
import CoefficientDetailsPage from "src/pages/quotes/coefficients/coefficient-container-details"
import ContainersListPage from "src/pages/quotes/containers/containers-list"
import ContainerCreatePage from "src/pages/quotes/containers/container-create"
import ContainerDetailsPage from "src/pages/quotes/containers/container-details"
import GroupsListPage from "src/pages/auth/groups/groups-list"
import GroupCreatePage from "src/pages/auth/groups/group-create"
import GroupDetailsPage from "src/pages/auth/groups/group-details"
import QuoteExportPage from "src/pages/quotes/quotes/quote-export"
import QuotePrintTwoPage from "src/pages/quotes/quotes/print-two"
// import MainPage from "src/pages/MainPage"
import { Permissions } from "src/@types/security"
import PermissionBasedGuard from "src/auth/PermissionBasedGuard"
import PackageTypesListPage from "src/pages/quotes/package-types/package-types-list"
import PackageTypeCreatePage from "src/pages/quotes/package-types/package-type-create"
import PackageTypeDetailsPage from "src/pages/quotes/package-types/package-type-details"
import UsersListPage from "src/pages/auth/users/users-list"
import UserDetailsPage from "src/pages/auth/users/user-details"
import UserCreatePage from "src/pages/auth/users/user-create"
import AuthGuard from "../auth/AuthGuard"
import GuestGuard from "../auth/GuestGuard"
// layouts
import CompactLayout from "../layouts/compact"
import DashboardLayout from "../layouts/dashboard"
// config
import { PATH_AFTER_LOGIN } from "../config-global"
//
import { Page404, LoginPage } from "./elements"

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: "/",
            element: <Navigate to="/quotes" />,
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                {
                    path: "login",
                    element: (
                        <GuestGuard>
                            <LoginPage />
                        </GuestGuard>
                    ),
                },
            ],
        },
        {
            path: "/security/groups",
            element: (
                <AuthGuard>
                    <PermissionBasedGuard permission={Permissions.GROUP_VIEW} hasContent>
                        <DashboardLayout />
                    </PermissionBasedGuard>
                </AuthGuard>
            ),
            children: [
                { path: "", element: <GroupsListPage /> },
                { path: "create", element: <GroupCreatePage /> },
                { path: ":id/", element: <GroupDetailsPage /> },
            ],
        },
        {
            path: "/security/users",
            element: (
                <AuthGuard>
                    <PermissionBasedGuard permission={Permissions.USER_VIEW} hasContent>
                        <DashboardLayout />
                    </PermissionBasedGuard>
                </AuthGuard>
            ),
            children: [
                { path: "", element: <UsersListPage /> },
                { path: ":id/", element: <UserDetailsPage /> },
                { path: "create", element: <UserCreatePage /> },
            ],
        },
        {
            path: "/quotes",
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                // { path: '/', element: <Navigate to="/quotes/categories/" replace /> },
                {
                    path: "categories",
                    element: (
                        <PermissionBasedGuard permission={Permissions.CATEGORY_UPDATE} hasContent>
                            <CategoriesList />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "categories/create",
                    element: (
                        <PermissionBasedGuard permission={Permissions.CATEGORY_CREATE} hasContent>
                            <CategoryCreate />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "categories/:id/",
                    element: (
                        <PermissionBasedGuard permission={Permissions.CATEGORY_UPDATE} hasContent>
                            <CategoryDetailsPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "material-types",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.MATERIAL_TYPE_VIEW}
                            hasContent
                        >
                            <MaterialTypesList />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "material-types/create",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.MATERIAL_TYPE_CREATE}
                            hasContent
                        >
                            <MaterialTypeCreatePage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "material-types/:id/",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.MATERIAL_TYPE_UPDATE}
                            hasContent
                        >
                            <MaterialTypeDetailsPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "measurement-types",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.MEASUREMENT_TYPE_UPDATE}
                            hasContent
                        >
                            <MeasurementTypesListPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "measurement-types/create",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.MEASUREMENT_TYPE_CREATE}
                            hasContent
                        >
                            <MeasurementTypeCreatePage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "measurement-types/:id/",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.MEASUREMENT_TYPE_UPDATE}
                            hasContent
                        >
                            <MeasurementTypeDetailsPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "line-item-types",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.LINE_ITEM_TYPE_UPDATE}
                            hasContent
                        >
                            <LineItemTypesListPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "line-item-types/create",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.LINE_ITEM_TYPE_CREATE}
                            hasContent
                        >
                            <LineItemTypeCreatePage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "line-item-types/:id/",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.LINE_ITEM_TYPE_UPDATE}
                            hasContent
                        >
                            <LineItemTypeDetailsPage />
                        </PermissionBasedGuard>
                    ),
                },
                { path: "quotes", element: <QuotesListPage /> },
                { path: "quotes/create", element: <QuotesCreatePage /> },
                { path: "quotes/:id/", element: <QuotesDetailsPage /> },
                { path: "quotes/print-two/:ids", element: <QuotePrintTwoPage /> },
                { path: "quotes/:id/view", element: <QuoteViewPage /> },
                {
                    path: "quotes/:id/export",
                    element: (
                        <PermissionBasedGuard permission={Permissions.QUOTE_EXPORT} hasContent>
                            <QuoteExportPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "quote-templates",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.QUOTE_TEMPLATE_UPDATE}
                            hasContent
                        >
                            <QuoteTemplatesListPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "quote-templates/create",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.QUOTE_TEMPLATE_CREATE}
                            hasContent
                        >
                            <QuoteTemplateCreatePage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "quote-templates/:id/",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.QUOTE_TEMPLATE_UPDATE}
                            hasContent
                        >
                            <QuoteTemplateDetailsPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "fee-types",
                    element: (
                        <PermissionBasedGuard permission={Permissions.FEE_TYPE_UPDATE} hasContent>
                            <FeeTypesListPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "fee-types/create",
                    element: (
                        <PermissionBasedGuard permission={Permissions.FEE_TYPE_CREATE} hasContent>
                            <FeeTypeCreatePage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "fee-types/:id/",
                    element: (
                        <PermissionBasedGuard permission={Permissions.FEE_TYPE_UPDATE} hasContent>
                            <FeeTypeDetailsPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "coefficients",
                    element: (
                        <PermissionBasedGuard permission={Permissions.CONTAINER_UPDATE} hasContent>
                            <CoefficientsListPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "coefficients/create",
                    element: (
                        <PermissionBasedGuard permission={Permissions.CONTAINER_CREATE} hasContent>
                            <CoefficientCreatePage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "coefficients/:id/",
                    element: (
                        <PermissionBasedGuard permission={Permissions.CONTAINER_UPDATE} hasContent>
                            <CoefficientDetailsPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "containers",
                    element: (
                        <PermissionBasedGuard permission={Permissions.CONTAINER_UPDATE} hasContent>
                            <ContainersListPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "containers/create",
                    element: (
                        <PermissionBasedGuard permission={Permissions.CONTAINER_CREATE} hasContent>
                            <ContainerCreatePage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "containers/:id/",
                    element: (
                        <PermissionBasedGuard permission={Permissions.CONTAINER_UPDATE} hasContent>
                            <ContainerDetailsPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "package-types",
                    element: (
                        <PermissionBasedGuard permission={Permissions.PACKAGE_TYPE_VIEW} hasContent>
                            <PackageTypesListPage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "package-types/create",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.PACKAGE_TYPE_CREATE}
                            hasContent
                        >
                            <PackageTypeCreatePage />
                        </PermissionBasedGuard>
                    ),
                },
                {
                    path: "package-types/:id/",
                    element: (
                        <PermissionBasedGuard
                            permission={Permissions.PACKAGE_TYPE_UPDATE}
                            hasContent
                        >
                            <PackageTypeDetailsPage />
                        </PermissionBasedGuard>
                    ),
                },
            ],
        },
        // {
        //     path: "/payroll",
        //     element: (
        //         <AuthGuard>
        //             <DashboardLayout />
        //         </AuthGuard>
        //     ),
        //     children: [
        //         // { path: '/', element: <Navigate to="/payroll/runs" replace />},
        //         { path: "runs", element: <PayrollRunsList /> },
        //         { path: "runs/create", element: <CreatePayrollRun /> },
        //         { path: "runs/:id/edit", element: <EditPayrollRun /> },
        //         { path: "pay-types", element: <PayTypesList /> },
        //         { path: "pay-types/create", element: <CreatePayTypeView /> },
        //         { path: "pay-types/:id/edit", element: <EditPayTypeView /> },
        //     ],
        // },
        {
            path: "/data",
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { path: "jobs", element: <JobsList /> },
                // { path: 'runs/create', element: <CreatePayrollRun /> }
            ],
        },
        {
            element: <CompactLayout />,
            children: [{ path: "404", element: <Page404 /> }],
        },
        // { path: "/", element: <MainPage />},
        { path: "*", element: <Navigate to="/404" replace /> },
    ])
}
