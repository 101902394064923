import { useEffect, useRef, useState } from "react"
import { TPackageItem } from "src/@types/package"
import { TLineItem } from "src/@types/lineItem"
import { TFee } from "src/@types/fee"
import { TMaterial } from "src/@types/material"
import { TFinancingOption } from "src/@types/financing-option"
import QuoteService from "src/services/quote-service"
import { calculatePackageTotal } from "src/services/package-service"
import { useDispatch, useSelector } from "src/redux/store"
import { updatePackage, updateQuote } from "src/redux/slices/quotes"
import {
    Checkbox,
    FormControlLabel,
    Grid,
    Link,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material"
import { Quote } from "src/@types/quote"
import EditTextCell from "./edit-text-cell"
import Logo from "./logo.png"
import LogoLightToANewDegree from "./light-to-a-degree-logo.jpeg"
import BottomImage from "./bottom-image.png"
import LineItemRow from "./line-item-row"
import FeeItemRow from "./fee-item-row"
import EditNumberCell from "./edit-number-cell"
import LineItemOneOptionRow from "./line-item-checkbox-row"

function calculateMonthlyPayment(principal: number, apr: number, termInMonths: number): number {
    console.log({ principal, apr, termInMonths })
    if (apr === 0) return principal / termInMonths

    const monthlyInterestRate = apr / 12 / 100
    const numerator = monthlyInterestRate * (1 + monthlyInterestRate) ** termInMonths
    const denominator = (1 + monthlyInterestRate) ** termInMonths - 1
    const monthlyPayment = principal * (numerator / denominator)
    return monthlyPayment
}

const formatPackagePrice = (
    price: number,
    financingOption: TFinancingOption | null,
    mode: priceDisplayMode
): JSX.Element => {
    const plainView = price.toLocaleString("en-US")
    if (financingOption === null || price < financingOption.min_amount || mode === "plain")
        return <>{plainView}</>

    const monthlyPayment = calculateMonthlyPayment(price, financingOption.apr, financingOption.term)
    const total = Math.round(monthlyPayment * financingOption.term)

    if (!monthlyPayment || !total) return <>{plainView}</>

    // console.log({ monthlyPayment, total })

    const financingView = (
        <>
            {Math.round(monthlyPayment).toLocaleString("en-US")}/mo.
            <span style={{ fontSize: 10 }}>*</span>
        </>
    )

    if (mode === "financing") return financingView

    return (
        <>
            {financingView} or {plainView}
        </>
    )
}

type priceDisplayMode = "plain" | "financing" | "both"

type Props = {
    quote: Quote
}

const QuoteTables = ({ quote }: Props) => {
    const dispatch = useDispatch()
    const [priceDisplayMode, setPriceDisplayMode] = useState<priceDisplayMode>("both")
    const [signature, setSignature] = useState(quote?.signature || "")
    const signatureRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (quote) {
            setSignature(quote.signature || "")
        }
    }, [quote])

    const onSignatureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setSignature(value)
        if (signatureRef.current) clearTimeout(signatureRef.current)
        signatureRef.current = setTimeout(() => {
            dispatch(updateQuote(quote.id, { signature: value }))
        }, 2000)
    }

    const onChangeChosenPackage = (packageId: number | null) => {
        dispatch(updateQuote(quote.id, { chosen_package_id: packageId }))
    }

    const displayOptions: (1 | 2 | 3)[] = [
        ...(quote.data.print_template?.display_options || [1, 2]),
    ].sort()
    const displayOptionsLabels: string[] = []
    displayOptionsLabels.push(QuoteService.getField(quote, "header_option_1", "White"))
    displayOptionsLabels.push(QuoteService.getField(quote, "header_option_2", "Dynamic"))
    displayOptionsLabels.push(QuoteService.getField(quote, "header_option_3", "-"))

    let lineItems = useSelector((state) => state.quotes.lineItemsByQuoteId[quote.id]) || []
    const lineItemsById = lineItems.reduce(
        (acc, item) => {
            acc[item.id] = item
            return acc
        },
        {} as { [key: number]: TLineItem }
    )
    lineItems = lineItems.filter((lineItem) => lineItem.value || lineItem.flat_fee)
    const fees = useSelector((state) => state.quotes.feesByQuoteId[quote.id]) || []
    const packages = useSelector((state) => state.quotes.packagesByQuoteId[quote.id]) || []
    const packagesMap = {
        null: packages[0],
        [packages[1]?.id]: packages[1],
        [packages[2]?.id]: packages[2],
        [packages[3]?.id]: packages[3],
    }
    const packageItemsByPackageId = useSelector((state) => state.quotes.packageItemsByPackageId)
    const materialsByLineItemId = useSelector((state) => state.quotes.materialsByLineItemId)
    const materialsById: {
        [key: number]: TMaterial
    } = {}
    lineItems.forEach((lineItem) => {
        materialsByLineItemId[lineItem.id].forEach((material) => {
            materialsById[material.id] = material
        })
    })
    lineItems = lineItems.filter(
        (lineItem) => materialsByLineItemId[lineItem.id]?.length > 0 || lineItem.flat_fee
    )
    const feesById: {
        [key: number]: TFee
    } = {}
    fees.forEach((fee) => {
        feesById[fee.id] = fee
    })

    let selectedTotal: number =
        calculatePackageTotal(
            packageItemsByPackageId[packagesMap[quote.chosen_package_id || "null"].id],
            lineItemsById,
            materialsById,
            feesById
            // suppliesAndMaterialsRounded
        ) - packagesMap[quote.chosen_package_id || "null"].discount_value

    if (quote.chosen_package_id === null && selectedTotal === 0) {
        // Sum up the most expensive material option for all line items
        lineItems.forEach((lineItem) => {
            selectedTotal += lineItem.flat_fee
            selectedTotal += Math.max(
                ...lineItem.materials.map((material) => {
                    if (displayOptions.includes(material.option)) return material.total
                    return 0
                }),
                0
            )
        })
        // Skip the dynamic fees
        selectedTotal += fees.reduce((acc, fee) => {
            if (fee.data.type === "dynamic") return acc
            return acc + fee.value
        }, 0)
        // Add 3% and roundup to the nearest 5
        selectedTotal = Math.ceil((selectedTotal * 1.03) / 5) * 5
    }

    return (
        <>
            <Grid container spacing={0} className="printable">
                <Grid item xs={12} spacing={0}>
                    {/* </Grid>
                        <Grid item container xs={4}> */}
                    <table className="quote-table">
                        {/* <thead> */}
                        <tr>
                            <td
                                style={{
                                    borderRight: "2px solid transparent",
                                    borderLeft: "2px solid transparent",
                                    borderTop: "2px solid transparent",
                                    paddingLeft: 0,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    whiteSpace: "nowrap",
                                }}
                                colSpan={4}
                            >
                                <Typography sx={{ color: "#208fce", pb: 0.5 }} variant="h6">
                                    {QuoteService.getField(
                                        quote,
                                        "header_top",
                                        "Holiday Lights Quote"
                                    )}
                                </Typography>
                            </td>
                            <td
                                rowSpan={3}
                                colSpan={2 + displayOptions.length}
                                style={{
                                    borderRight: "2px solid transparent",
                                    borderTop: "2px solid transparent",
                                    paddingRight: 0,
                                    paddingTop: 0,
                                    verticalAlign: "top",
                                }}
                            >
                                <img
                                    src={QuoteService.getField(quote, "top_right_logo_url", Logo)}
                                    alt="logo"
                                    style={{ width: "80px", float: "right" }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={3}> Date</th>
                            <th>Number</th>
                        </tr>
                        <tr className="text-regular">
                            <td
                                style={{
                                    textAlign: "center",
                                    // fontWeight: 700
                                }}
                                colSpan={3}
                            >
                                {quote.sf_estimate?.start_date
                                    ? new Date(quote.sf_estimate?.start_date).toLocaleDateString()
                                    : null}
                            </td>
                            <td
                                style={{
                                    textAlign: "center",
                                    // fontWeight: 700
                                }}
                            >
                                {quote.sf_estimate?.number}
                            </td>
                        </tr>
                        {/* </tbody> */}
                        {/* <thead> */}
                        <tr>
                            <th style={{}} colSpan={4}>
                                Client Information
                            </th>
                            <th colSpan={2 + displayOptions.length}>
                                {QuoteService.getField(
                                    quote,
                                    "header_order_details",
                                    "Lease Details"
                                )}
                            </th>
                        </tr>
                        {/* </thead> */}
                        {/* <tbody> */}
                        <tr>
                            <td
                                colSpan={4}
                                style={{
                                    borderBottom: "none",
                                    textAlign: "center",
                                    // fontWeight: 700,
                                }}
                                className="text-regular"
                            >
                                {quote.sf_estimate?.customer.name}
                            </td>
                            <EditTextCell
                                state={quote.notes}
                                onChange={(value: string) => {
                                    dispatch(updateQuote(quote.id, { notes: value }))
                                }}
                                name="Job Notes / Instructions"
                                colSpan={2 + displayOptions.length}
                                // cellStyle={{ borderBottom: "none" }}
                            />
                        </tr>
                        <tr>
                            <th
                            // rowSpan={2}
                            >
                                1
                            </th>
                            <th
                            // rowSpan={2}
                            >
                                2
                            </th>
                            <th
                            // rowSpan={2}
                            >
                                3
                            </th>
                            <th
                                // rowSpan={2}
                                style={{ whiteSpace: "nowrap" }}
                            >
                                {QuoteService.getField(quote, "header_service", "Light Service")}
                            </th>
                            <th
                            // rowSpan={2}
                            >
                                Description
                            </th>
                            {displayOptions.map((option) => (
                                <th
                                    style={{
                                        // width: displayOptions.length === 1 ? 60 : 60,
                                        width: 60,
                                    }}
                                >
                                    {QuoteService.getField(
                                        quote,
                                        `header_option_${option}`,
                                        displayOptionsLabels[option - 1]
                                    )}
                                </th>
                            ))}
                            <th />
                        </tr>
                        {/* <tr>
                                
                            </tr> */}
                        {/* </thead> */}
                        {/* <tbody> */}
                        {lineItems.map((lineItem) => {
                            const packageItems: TPackageItem[] = []
                            packages.forEach((p) => {
                                const packageItem = packageItemsByPackageId[p.id].find(
                                    (pi) => pi.line_item_id === lineItem.id
                                )
                                if (packageItem) {
                                    packageItems.push(packageItem)
                                }
                            })
                            // console.log({ lineItem })
                            if (
                                displayOptions.length === 1 ||
                                // lineItem.data.collapse_empty_material_options &&
                                materialsByLineItemId[lineItem.id].length <= 1
                            ) {
                                return (
                                    <LineItemOneOptionRow
                                        lineItem={lineItem}
                                        key={lineItem.id}
                                        packageItems={packageItems}
                                        packages={packages}
                                        materials={materialsByLineItemId[lineItem.id]}
                                        displayOptions={displayOptions}
                                    />
                                )
                            }
                            return (
                                <LineItemRow
                                    displayOptions={displayOptions}
                                    displayOptionsLabels={displayOptionsLabels}
                                    lineItem={lineItem}
                                    key={lineItem.id}
                                    packageItems={packageItems}
                                    packages={packages}
                                    materials={materialsByLineItemId[lineItem.id]}
                                />
                            )
                        })}
                        {fees.map((fee) => {
                            const packageItems: TPackageItem[] = []
                            packages.forEach((p) => {
                                const packageItem = packageItemsByPackageId[p.id].find(
                                    (pi) => pi.fee_id === fee.id
                                )
                                if (packageItem) {
                                    packageItems.push(packageItem)
                                }
                            })
                            // console.log(fee)
                            // console.log("fee", fee.data)
                            // console.log(packagesMap[quote.chosen_package_id || "null"])
                            // console.log({packagesMap, chosen: quote.chosen_package_id})
                            if (fee.data.visibility?.selected_package !== undefined) {
                                if (
                                    fee.data.visibility.selected_package !==
                                    packagesMap[quote.chosen_package_id || "null"].order
                                )
                                    return null
                            }
                            return (
                                <FeeItemRow
                                    displayOptions={displayOptions}
                                    fee={fee}
                                    key={fee.id}
                                    packageItems={packageItems}
                                    packages={packages}
                                    allFees={fees}
                                />
                            )
                        })}
                    </table>
                </Grid>
            </Grid>
            <Stack spacing={1} direction="column">
                {quote.financing_options.length > 0 && (
                    <Stack
                        direction="row"
                        spacing={1}
                        className="non-printable"
                        alignItems="center"
                        sx={{ mt: 2 }}
                    >
                        <Typography variant="body2">
                            <b>Financing:</b>
                        </Typography>

                        <ToggleButtonGroup
                            size="small"
                            value={priceDisplayMode}
                            exclusive
                            onChange={(e, value) => {
                                if (value) setPriceDisplayMode(value)
                            }}
                            sx={{ padding: 0 }}
                        >
                            <ToggleButton value="plain" sx={{ padding: 1, lineHeight: 0.7 }}>
                                Total
                            </ToggleButton>
                            <ToggleButton value="financing" sx={{ padding: 1, lineHeight: 0.7 }}>
                                Financing
                            </ToggleButton>
                            <ToggleButton value="both" sx={{ padding: 1, lineHeight: 0.7 }}>
                                Both
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Boolean(quote.chosen_financing_option_id)}
                                    onChange={(e) => {
                                        dispatch(
                                            updateQuote(quote.id, {
                                                chosen_financing_option_id: e.target.checked
                                                    ? quote.financing_options[0].id
                                                    : null,
                                            })
                                        )
                                    }}
                                />
                            }
                            label="Client using WiseTack"
                            sx={{ fontSize: 12 }}
                        />
                    </Stack>
                )}
                <table className="quote-table packages-table" style={{ marginTop: 0 }}>
                    <thead>
                        <tr>
                            <th colSpan={3}>Service Packages</th>
                            <th colSpan={2} style={{ width: "152px" }} />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    width: "calc((100% - 152px) / 3)",
                                    // maxWidth: "30%",
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    backgroundColor: "orange",
                                    textAlign: "center",
                                    // fontWeight: 700,
                                }}
                            >
                                <input
                                    type="checkbox"
                                    style={{ marginRight: 8 }}
                                    checked={quote.chosen_package_id === packages[1].id}
                                    onChange={(e) =>
                                        onChangeChosenPackage(
                                            e.target.checked ? packages[1].id : null
                                        )
                                    }
                                />
                                1. {packages[1].name}
                            </td>
                            <td
                                style={{
                                    width: "calc((100% - 152px) / 3)",
                                    // minWidth: "30%",
                                    // maxWidth: "30%",
                                    backgroundColor: "yellow",
                                    textAlign: "center",
                                    // fontWeight: 700,
                                }}
                            >
                                <input
                                    type="checkbox"
                                    style={{ marginRight: 8 }}
                                    checked={quote.chosen_package_id === packages[2].id}
                                    onChange={(e) =>
                                        onChangeChosenPackage(
                                            e.target.checked ? packages[2].id : null
                                        )
                                    }
                                />
                                2. {packages[2].name}
                            </td>
                            <td
                                style={{
                                    width: "calc((100% - 152px) / 3)",
                                    // minWidth: "25%",
                                    // maxWidth: "30%",
                                    backgroundColor: "LawnGreen",
                                    textAlign: "center",
                                    // fontWeight: 700,
                                }}
                            >
                                <input
                                    type="checkbox"
                                    style={{ marginRight: 8 }}
                                    checked={quote.chosen_package_id === packages[3].id}
                                    onChange={(e) =>
                                        onChangeChosenPackage(
                                            e.target.checked ? packages[3].id : null
                                        )
                                    }
                                />
                                3. {packages[3].name}
                            </td>
                            <td
                                // width="120px"
                                style={{
                                    width: "100px",
                                    backgroundColor: "white",
                                    textAlign: "center",
                                }}
                            >
                                Selected
                            </td>
                            <td
                                style={{
                                    // width: "50px",
                                    backgroundColor: "white",
                                    textAlign: "center",
                                    color: "black",
                                    fontWeight: 700,
                                }}
                            >
                                {calculatePackageTotal(
                                    packageItemsByPackageId[
                                        packagesMap[quote.chosen_package_id || "null"].id
                                    ],
                                    // quote.chosen_package_id
                                    //     ? quote.chosen_package_id]
                                    //     : packageItemsByPackageId[packages[0].id],
                                    lineItemsById,
                                    materialsById,
                                    feesById
                                    // suppliesAndMaterialsRounded
                                ).toLocaleString("en-US")}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    // width: "25%",
                                    backgroundColor: "orange",
                                    textAlign: "center",
                                    // fontWeight: 700,
                                }}
                            >
                                {formatPackagePrice(
                                    calculatePackageTotal(
                                        packageItemsByPackageId[packages[1].id],
                                        lineItemsById,
                                        materialsById,
                                        feesById
                                        // suppliesAndMaterialsRounded
                                    ) - packages[1].discount_value,
                                    quote.financing_options[0] || null,
                                    priceDisplayMode
                                )}
                            </td>
                            <td
                                style={{
                                    // width: "25%",
                                    backgroundColor: "yellow",
                                    textAlign: "center",
                                    // fontWeight: 700,
                                }}
                            >
                                {formatPackagePrice(
                                    calculatePackageTotal(
                                        packageItemsByPackageId[packages[2].id],
                                        lineItemsById,
                                        materialsById,
                                        feesById
                                        // suppliesAndMaterialsRounded
                                    ) - packages[2].discount_value,
                                    quote.financing_options[0] || null,
                                    priceDisplayMode
                                )}
                            </td>
                            <td
                                style={{
                                    // width: "25%",
                                    backgroundColor: "LawnGreen",
                                    textAlign: "center",
                                    // fontWeight: 700,
                                }}
                            >
                                {formatPackagePrice(
                                    calculatePackageTotal(
                                        packageItemsByPackageId[packages[3].id],
                                        lineItemsById,
                                        materialsById,
                                        feesById
                                        // suppliesAndMaterialsRounded
                                    ) - packages[3].discount_value,
                                    quote.financing_options[0] || null,
                                    priceDisplayMode
                                )}
                            </td>
                            <td
                                style={{
                                    // width: "12.5%",
                                    backgroundColor: "white",
                                    textAlign: "center",
                                }}
                            >
                                Discount
                            </td>
                            <EditNumberCell
                                state={
                                    packagesMap[quote.chosen_package_id || "null"].discount_value
                                }
                                // cellStyle={{ fontSize: 14 }}
                                onChange={(value) => {
                                    dispatch(
                                        updatePackage(
                                            packagesMap[quote.chosen_package_id || "null"].id,
                                            {
                                                discount_value: value,
                                            }
                                        )
                                    )
                                }}
                                name={packages[0].name}
                                cellStyle={{ color: "black", fontWeight: 700 }}
                            />
                            {/* <td style={{ width: "12.5%", backgroundColor: "white" }}>
                                    {packages[0].discount_value}
                                </td> */}
                        </tr>
                        <tr>
                            <EditNumberCell
                                state={packages[1].discount_value}
                                cellStyle={{
                                    backgroundColor: "orange",
                                    fontWeight: 700,
                                    // fontSize: 16
                                }}
                                onChange={(value) => {
                                    dispatch(
                                        updatePackage(packages[1].id, {
                                            discount_value: value,
                                        })
                                    )
                                }}
                                name={packages[1].name}
                                formatState={(value) => `Save $${value?.toLocaleString()}`}
                            />
                            <EditNumberCell
                                state={packages[2].discount_value}
                                cellStyle={{
                                    backgroundColor: "yellow",
                                    fontWeight: 700,
                                    // fontSize: 16
                                }}
                                onChange={(value) => {
                                    dispatch(
                                        updatePackage(packages[2].id, {
                                            discount_value: value,
                                        })
                                    )
                                }}
                                name={packages[2].name}
                                formatState={(value) => `Save $${value?.toLocaleString()}`}
                            />
                            <EditNumberCell
                                state={packages[3].discount_value}
                                cellStyle={{
                                    backgroundColor: "LawnGreen",
                                    fontSize: 16,
                                    fontWeight: 700,
                                }}
                                onChange={(value) => {
                                    dispatch(
                                        updatePackage(packages[3].id, {
                                            discount_value: value,
                                        })
                                    )
                                }}
                                name={packages[3].name}
                                formatState={(value) => `Save $${value?.toLocaleString()}`}
                            />
                            <td
                                style={{
                                    // width: "12.5%",
                                    backgroundColor: "white",
                                    textAlign: "center",
                                }}
                            >
                                {/* <input type="checkbox" style={{ marginRight: 8 }} /> */}
                                Total
                            </td>
                            <td
                                style={{
                                    // width: "12.5%",
                                    backgroundColor: "white",
                                    textAlign: "center",
                                    color: "black",
                                    fontWeight: 700,
                                }}
                            >
                                {selectedTotal.toLocaleString("en-US")}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Stack spacing={1} direction="column" sx={{ mt: 1 }}>
                    {quote.financing_options.length > 0 && (
                        <div className="text-small">
                            * Three month term subject to credit approval and varies. Finance
                            options with APR rates from 0-35.9% based on creditworthiness provided
                            by Wisetack’s lending partners. Details at{" "}
                            <Link href="https://wisetack.com/faqs" color="#208fce" target="_blank">
                                wisetack.com/faqs
                            </Link>
                        </div>
                    )}

                    {/* <div className="text-small">
                                The full amount for materials, installation, and removal must be
                                paid in full upon completion of the installation. WiseGuys Pro-Wash
                                is not responsible for any products damaged or lost due to
                                vandalism, extreme weather conditions, or acts of god and will make
                                efforts to replace any damaged product for an additional charge. No
                                warranty or complimentary repair service is expressed or implied,
                                unless noted in writing in this agreement.
                            </div> */}
                    <div className="text-small">
                        {QuoteService.getField(
                            quote,
                            "text_under_package_options",
                            `WiseGuys Pro-Wash replaces reported malfunctioning products, but
                                cannot guarantee that every bulb remains lit during the length of
                                the lease. Customers are responsible for replacing items damaged or
                                lost due to vandalism or acts of nature, incurring an additional
                                charge. “Supplies” added to all jobs. A 50% credit card or ACH
                                deposit is due upon job scheduling with balance charged upon
                                completing installation for the job’s total (including materials and
                                removal) unless financed through WiseTack.`
                        )}
                    </div>
                    {/* <div className="text-small"> */}
                    {/* &quot;Supplies & Materials&quot; added to all jobs (dependent on size).
                            A 50% credit card deposit due upon job scheduling with balance charged
                            upon completing installation. */}
                    {/* &quot;Supplies&quot; added to all jobs (dependent on size). A 50%
                                credit card or ACH deposit due upon job scheduling with balance
                                charged upon completing installation for job total (including
                                materials & removal) unless financed through WiseTack.
                            </div> */}
                </Stack>
                <Grid container spacing={0} className="printable">
                    <Grid
                        item
                        // xs={7}
                        width="50%"
                        spacing={0}
                        container
                        alignItems="flex-start"
                        direction="column"
                        alignContent="flex-start"
                        justifyContent="flex-start"
                    >
                        <img
                            src={QuoteService.getField(quote, "bottom_left_logo_url", BottomImage)}
                            alt="bottom-logo"
                            style={{ width: "196px", marginTop: 4 }}
                        />
                        {quote.data.print_template?.fields?.bottom_left_logo_2_url !== "-" && (
                            <img
                                src={QuoteService.getField(
                                    quote,
                                    "bottom_left_logo_2_url",
                                    LogoLightToANewDegree
                                )}
                                alt="bottom-logo"
                                style={{ width: "196px", paddingBottom: 8, marginTop: 0 }}
                            />
                        )}

                        {/* <Typography
                                    sx={{ color: "#208fce", fontSize: 20, fontWeight: "bold" }}
                                >
                                    Light to a new degree!
                                </Typography> */}
                    </Grid>
                    <Grid
                        item
                        // xs={5}
                        width="50%"
                        spacing={0}
                    >
                        <table style={{ width: "100%", marginTop: 6, marginLeft: -4 }}>
                            <tr>
                                <td
                                    style={{
                                        width: "40%",
                                        color: "#208fce",
                                        fontSize: 12,
                                        fontWeight: 700,
                                        textAlign: "left",
                                        paddingRight: 8,
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    Customer Signature:
                                </td>
                                <td style={{ width: "60%" }}>
                                    <input
                                        type="text"
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                            color: "black",
                                            border: "none",
                                            borderBottom: "1px solid #208fce",
                                            fontFamily: "Euphoria Script",
                                            fontSize: 20,
                                            outline: "none",
                                            WebkitBorderRadius: 0,
                                            borderRadius: 0,
                                            WebkitAppearance: "none",
                                        }}
                                        value={signature}
                                        onChange={onSignatureChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan={2}
                                    style={{
                                        // fontSize: 10,
                                        // fontWeight: 700,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                    }}
                                    className="text-small"
                                >
                                    {/* <b> */}
                                    {/* Governed by our Terms & Conditions on the reverse. We
                                            expect payment the day after installation by credit card
                                            (+3% service fee) or ACH. */}
                                    {QuoteService.getField(
                                        quote,
                                        "text_under_customer_signature",
                                        `Governed by Terms & Conditions on reverse. Payment due
                                                the day after installation by credit card (with 3%
                                                service fee) or ACH, unless financed with WiseTack
                                                (prequalify at`
                                    )}
                                    {/* </b> */}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        // width: "50%",
                                        color: "#208fce",
                                        fontSize: 12,
                                        fontWeight: 700,
                                        textAlign: "left",
                                        paddingRight: 8,
                                    }}
                                >
                                    {QuoteService.getField(
                                        quote,
                                        "header_consultant_signature",
                                        "Design Consultant:"
                                    )}
                                </td>
                                <td
                                    style={{
                                        // width: "50%",
                                        borderBottom: "1px solid #208fce",
                                        textAlign: "center",
                                        color: "black",
                                        fontSize: 12,
                                    }}
                                >
                                    {`${quote.created_by?.first_name} ${quote.created_by?.last_name}`}
                                </td>
                            </tr>
                        </table>
                        {/* <Typography sx={{ display: "inline-block" }}>
                                <p></p>{" "}
                                <div style={{ borderBottom: "1px solid black", width: "50px" }} />
                            </Typography> */}
                    </Grid>
                </Grid>
            </Stack>
        </>
    )
}

export default QuoteTables
