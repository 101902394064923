import { Quote } from "src/@types/quote"
import LegalTerms from "./toc.json"

type Props = {
    quote: Quote
}

const QuoteTermsAndConditions = ({ quote }: Props) => {
    console.log("T&C")
    return (
        <div className="printable-only">
            <div
                style={
                    {
                        // width: "100%",
                        // columnCount: 2,
                        // gap: "2em",
                        // columnFill: "balance",
                    }
                }
                // className="toc"
            >
                <div
                    style={{
                        marginBottom: 2,
                        display: "flex",
                        justifyContent: "center",
                        fontSize: 12,
                        // columnSpan: "all",
                    }}
                >
                    <b>{quote.terms_and_conditions?.data.title || "Terms & Conditions"}</b>
                </div>
                <div>
                    {/* <ol type="1"> */}
                    {(quote.terms_and_conditions?.data.sections || LegalTerms).map(
                        (item, index) => (
                            // <li key={index}>
                            <p key={index}>
                                <b>
                                    {index + 1}. {item.title}.
                                </b>{" "}
                                {item.text}
                            </p>
                            // </li>
                        )
                    )}
                    {/* </ol> */}
                </div>
            </div>
        </div>
    )
}

export default QuoteTermsAndConditions
