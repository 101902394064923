import {
    Button,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    Typography,
    TableBody,
    Card,
    ToggleButtonGroup,
    ToggleButton,
    FormControlLabel,
    Checkbox,
} from "@mui/material"
import QuoteService from "src/services/quote-service"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { Quote, QuoteUpdate } from "src/@types/quote"
import AddIcon from "@mui/icons-material/Add"
import { getLineItemTypes } from "src/redux/slices/lineItemTypes"
import { getCategories } from "src/redux/slices/categories"
import {
    addFeeToQuote,
    addFinancingOptionToQuote,
    deleteFee,
    deleteFinancingOption,
    updateFinancingOption,
    updateQuote,
} from "src/redux/slices/quotes"
import { TFeeType } from "src/@types/fee-type"
import { TFee } from "src/@types/fee"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import { getFeeTypes } from "src/redux/slices/fee-types"
import { Permissions } from "src/@types/security"
import { useAuthContext } from "src/auth/useAuthContext"
import { getFinancingOptionTypes } from "src/redux/slices/financing-option-types"
import { TFinancingOption, TFinancingOptionUpdate } from "src/@types/financing-option"
import { TFinancingOptionType } from "src/@types/financing-option-type"
import TextFieldWithSave from "./text-field-with-save"
import VariableField from "./variable"
import QuoteContentItems from "./quote-items"

type Props = {
    quote: Quote
    handleCreateRelatedQuote: (template_id: number) => void
}

const QuoteContent = ({ quote, handleCreateRelatedQuote }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [feesMenuOpen, setFeesMenuOpen] = useState<boolean>(false)
    const [financingOptionsMenuOpen, setFinancingOptionsMenuOpen] = useState<boolean>(false)
    const [displayOnlyCompletedItems, setDisplayOnlyCompletedItems] = useState<boolean>(false)
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
        setFeesMenuOpen(false)
        setFinancingOptionsMenuOpen(false)
    }

    const { hasPermission } = useAuthContext()

    const navigate = useNavigate()

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        dispatch(getCategories())
        dispatch(getLineItemTypes())
        dispatch(getFeeTypes())
        dispatch(getFinancingOptionTypes())
    }, [dispatch])

    const feeTypes = useSelector((state) => state.feeTypes.feeTypes)
    const financingOptionTypes = useSelector(
        (state) => state.financingOptionTypes.financingOptionTypes
    )
    const fees = useSelector((state) => state.quotes.feesByQuoteId[quote.id]) || []
    const financingOptions =
        useSelector((state) => state.quotes.financingOptionsByQuoteId[quote.id]) || []

    const onSubmit = async (data: QuoteUpdate) => {
        try {
            const response = await dispatch(updateQuote(quote.id, data))
            if (response.status === 200) {
                enqueueSnackbar("Quote updated", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const displayOptions: (1 | 2 | 3)[] = quote.data.print_template?.display_options || [1, 2]
    const displayOptionsLabels: string[] = []
    displayOptionsLabels.push(QuoteService.getField(quote, "header_option_1", "White"))
    displayOptionsLabels.push(QuoteService.getField(quote, "header_option_2", "Dynamic"))
    displayOptionsLabels.push(QuoteService.getField(quote, "header_option_3", "-"))
    const displayOptionsMap: { [key: number]: string } = {}
    displayOptionsLabels.forEach((item, index) => {
        if (item === "-" || !item) return
        displayOptionsMap[index + 1] = item
    })

    const onChangeMaterialOptionColumns = (value: (1 | 2 | 3)[]) => {
        const newData = JSON.parse(JSON.stringify(quote.data))
        if (!newData.print_template) newData.print_template = {}
        newData.print_template.display_options = value
        onSubmit({ data: newData })
    }

    const onCreateFee = async (feeType: TFeeType) => {
        if (!feeType.id) return
        const response = await dispatch(addFeeToQuote(quote.id, feeType.id))
        if (response.status === 201) {
            enqueueSnackbar(`Fee ${feeType.name} added`, {
                variant: "success",
            })
        }
    }

    const onDeleteFee = async (fee: TFee) => {
        if (!window.confirm("Are you sure you want to delete this fee?")) return
        const response = await dispatch(deleteFee(fee))
        if (response.status === 204) {
            enqueueSnackbar(`Fee ${fee.name} deleted`, {
                variant: "success",
            })
        }
    }

    const onCreateFinancingOption = async (item: TFinancingOptionType) => {
        if (!item.id) return
        const response = await dispatch(addFinancingOptionToQuote(quote.id, item.id))
        if (response.status === 201) {
            enqueueSnackbar(`Financing option ${item.name} added`, {
                variant: "success",
            })
        }
    }

    const onDeleteFinancingOption = async (item: TFinancingOption) => {
        if (!window.confirm("Are you sure you want to delete this financing option?")) return
        const response = await dispatch(deleteFinancingOption(item))
        if (response.status === 204) {
            enqueueSnackbar(`Fee ${item.name} deleted`, {
                variant: "success",
            })
        }
    }

    const onUpdateFinancingOption = async (
        item: TFinancingOption,
        changes: TFinancingOptionUpdate
    ) => {
        const response = await dispatch(updateFinancingOption(item.id, changes))
        if (response.status === 200) {
            enqueueSnackbar(`Financing option updated`, {
                variant: "success",
            })
        } else {
            enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
        }
    }

    return (
        <>
            {(quote.template?.children.length || 0) > 0 && quote.related_quotes.length === 0 ? (
                <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    onClick={() => {
                        if (quote.template?.children[0].child_id) {
                            handleCreateRelatedQuote(quote.template?.children[0].child_id)
                        }
                    }}
                >
                    {quote.template?.children[0].data.message}
                </Button>
            ) : null}
            {quote.related_quotes.length > 0 ? (
                <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    onClick={() => {
                        navigate(PATHS.quotes.quotes.details(quote.related_quotes[0].id))
                    }}
                >
                    Go to Related Quote
                </Button>
            ) : null}
            {quote.data.variables?.length ? (
                <>
                    <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                        Variables
                    </Typography>
                    <Stack direction="column" spacing={1}>
                        {quote.data.variables.map((variable, index) => (
                            <VariableField quoteId={quote.id} variable={variable} key={index} />
                        ))}
                    </Stack>
                </>
            ) : null}
            {Object.keys(displayOptionsMap).length > 1 &&
                (quote.data.print_template?.max_display_options || 3) > 1 && (
                    <>
                        <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                            Material Options Columns (Max{" "}
                            {quote.data.print_template?.max_display_options || 3})
                        </Typography>
                        <Stack direction="row" spacing={2}>
                            <ToggleButtonGroup
                                value={displayOptions}
                                onChange={async (e, values) => {
                                    onChangeMaterialOptionColumns(values)
                                }}
                                size="small"
                            >
                                {Object.keys(displayOptionsMap).map((key) => (
                                    <ToggleButton
                                        key={`display_option_${key}`}
                                        value={Number(key)}
                                        disabled={
                                            !displayOptions.includes(Number(key) as 1 | 2 | 3) &&
                                            displayOptions.length ===
                                                (quote.data.print_template?.max_display_options ||
                                                    3)
                                        }
                                    >
                                        {displayOptionsMap[Number(key)]}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Stack>
                    </>
                )}
            <Stack direction="row" spacing={1} alignItems="center" pt={3} pb={2}>
                <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
                    Services
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={displayOnlyCompletedItems}
                            onChange={() => setDisplayOnlyCompletedItems((v) => !v)}
                        />
                    }
                    label="Display only completed items"
                />
            </Stack>
            <QuoteContentItems
                quote={quote}
                displayOnlyCompletedItems={displayOnlyCompletedItems}
            />
            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Fees{" "}
                <IconButton
                    size="small"
                    color="default"
                    sx={{ mt: -1 }}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleOpenMenu(e)
                        setFeesMenuOpen(true)
                    }}
                >
                    <AddIcon />
                </IconButton>
                <Menu
                    id="add-fees-menu"
                    anchorEl={anchorEl}
                    open={feesMenuOpen}
                    onClose={handleCloseMenu}
                >
                    {feeTypes.map((feeType) => (
                        <MenuItem
                            key={feeType.id}
                            onClick={(e) => {
                                onCreateFee(feeType)
                                console.log(feeType)
                                handleCloseMenu()
                            }}
                        >
                            {feeType.name}
                        </MenuItem>
                    ))}
                </Menu>
            </Typography>
            <Stack direction="column" spacing={0}>
                <Card>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fees.map((fee) => (
                                    <TableRow hover key={fee.id}>
                                        <TableCell>{fee.name}</TableCell>
                                        <TableCell>{fee.description}</TableCell>
                                        <TableCell>{fee.value}</TableCell>
                                        <TableCell>
                                            <Button
                                                size="small"
                                                color="error"
                                                variant="outlined"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    onDeleteFee(fee)
                                                }}
                                                disabled={
                                                    fee.data.restrictions?.can_be_deleted === false
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Stack>
            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Financing Options{" "}
                {
                    // Allow adding financing options only if there are none
                    financingOptions.length === 0 && (
                        <>
                            <IconButton
                                size="small"
                                color="default"
                                sx={{ mt: -1 }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleOpenMenu(e)
                                    setFinancingOptionsMenuOpen(true)
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                            <Menu
                                id="add-financing-option-menu"
                                anchorEl={anchorEl}
                                open={financingOptionsMenuOpen}
                                onClose={handleCloseMenu}
                            >
                                {financingOptionTypes.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        onClick={(e) => {
                                            onCreateFinancingOption(item)
                                            handleCloseMenu()
                                        }}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    )
                }
            </Typography>
            <Stack direction="column" spacing={0}>
                <Card>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>Name</TableCell>
                                                    <TableCell>Description</TableCell> */}
                                    <TableCell>Min Amount</TableCell>
                                    <TableCell>APR (%)</TableCell>
                                    <TableCell>Term (Month)</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {financingOptions.map((item) => (
                                    <TableRow hover key={item.id}>
                                        {/* <TableCell>{item.name}</TableCell>
                                                        <TableCell>{item.description}</TableCell> */}
                                        <TableCell>
                                            {`$${item.min_amount.toLocaleString()}`}
                                            {/* <TextFieldWithSave
                                                                initialValue={item.min_amount}
                                                                onSave={(value) =>
                                                                    onUpdateFinancingOption(item, {
                                                                        min_amount: Number(value),
                                                                    })
                                                                }
                                                            /> */}
                                        </TableCell>
                                        <TableCell>
                                            <TextFieldWithSave
                                                initialValue={item.apr}
                                                onSave={(value) =>
                                                    onUpdateFinancingOption(item, {
                                                        apr: Number(value),
                                                    })
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextFieldWithSave
                                                initialValue={item.term}
                                                onSave={(value) =>
                                                    onUpdateFinancingOption(item, {
                                                        term: Number(value),
                                                    })
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                size="small"
                                                color="error"
                                                variant="outlined"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    onDeleteFinancingOption(item)
                                                }}
                                                disabled={
                                                    !hasPermission(
                                                        Permissions.FINANCING_OPTION_DELETE
                                                    )
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Stack>
            <div>
                <Button
                    variant="contained"
                    size="small"
                    sx={{ mt: 3 }}
                    onClick={() => navigate(PATHS.quotes.quotes.view(quote.id))}
                >
                    Done, go to View
                </Button>
            </div>
        </>
    )
}

export default QuoteContent
