import Handlebars from "handlebars"
import { CustomField } from "src/@types/custom-field"

interface NestedObject {
    [key: string]: any
}

Handlebars.registerHelper("map", (array: NestedObject[], key: string): string[] =>
    array.map((item) => item[key])
)

Handlebars.registerHelper("groupAndJoin", (values, options) => {
    const separator = options.hash.separator || "/"
    const joinDuplicates = options.hash.joinDuplicates || false
    const parts: string[] = []
    let count = 1

    for (let i = 0; i < values.length - 1; i += 1) {
        if (!joinDuplicates) {
            parts.push(values[i])
        } else if (values[i] === values[i + 1]) {
            count += 1
        } else {
            if (count > 1) {
                parts.push(`${count}${values[i]}`)
            } else {
                parts.push(values[i])
            }
            count = 1
        }
    }

    // Handle the last group
    if (joinDuplicates && count > 1) {
        parts.push(`${count}${values[values.length - 1]}`)
    } else {
        parts.push(values[values.length - 1])
    }
    return parts.join(separator)
})

export const renderCustomFieldValue = (field: CustomField, template?: string): string => {
    if (field.render.type === "string") {
        let value
        if (field.values !== undefined) {
            value = field.values[0]
        } else {
            // @ts-ignore
            value = field.value
        }
        return value?.value || ""
    }
    return Handlebars.compile(template || field.render.template)(field)
}
