/* eslint-disable */

import { Autocomplete, Stack, TextField } from "@mui/material"
import { useSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { Quote } from "src/@types/quote"
import { TLineItem } from "src/@types/lineItem"
import { createLineItem, reorderQuoteLineItems } from "src/redux/slices/quotes"
import { useDispatch, useSelector } from "src/redux/store"
import { TLineItemType } from "src/@types/lineItemType"
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd"
import QuoteSectionDetails from "./quote-section-details"
import LineItemService, { getLineItemName } from "src/services/line-item-service"
import LineItemDetails from "./line-item-details"

type Props = {
    quote: Quote
    displayOnlyCompletedItems: boolean
}

const QuoteContentItems = ({ quote, displayOnlyCompletedItems }: Props) => {
    // const [isLoading, setIsLoading] = useState(true)
    const [lineItemTypeToCreate, setLineItemTypeToCreate] = useState<TLineItemType | null>(null)
    const [isSending, setIsSending] = useState(false)
    const [expandedLineItemId, setExpandedLineItemId] = useState<number | null>(null)

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const allLineItemTypes = useSelector((state) => state.lineItemTypes.lineItemTypes)
    const { byParentIdNested: categoriesByParentIdNested } = useSelector(
        (state) => state.categories.categories
    )
    const materialsByLineItemId = useSelector((state) => state.quotes.materialsByLineItemId || {})
    const measurementsByLineItemId = useSelector(
        (state) => state.quotes.measurementsByLineItemId || {}
    )
    const lineItems = useSelector((state) => state.quotes.lineItemsByQuoteId[quote.id]) || []
    const sections = useSelector((state) => state.quotes.sectionsByQuoteId[quote.id]) || []
    const lineItemsBySections: { [key: number]: TLineItem[] } = { 0: [] }
    lineItems.forEach((item) => {
        if (!lineItemsBySections[item.section_id || 0]) {
            lineItemsBySections[item.section_id || 0] = []
        }
        lineItemsBySections[item.section_id || 0].push(item)
    })

    const [lineItemsState, setLineItemsState] = useState(lineItemsBySections[0])

    useEffect(() => {
        setLineItemsState(lineItems)
    }, [JSON.stringify(lineItems)])

    const allowedLineItemTypesIds = quote.allowed_line_item_types?.map((item) => item.id) || []
    const allowedLineItemCategoriesIds: number[] = []
    if (quote.allowed_line_item_categories) {
        allowedLineItemCategoriesIds.push(
            ...quote.allowed_line_item_categories.map((item) => item.id)
        )
        quote.allowed_line_item_categories.forEach((item) => {
            allowedLineItemCategoriesIds.push(...(categoriesByParentIdNested[item.id] || []))
        })
    }
    if (quote.category_id && allowedLineItemCategoriesIds.length === 0) {
        allowedLineItemCategoriesIds.push(quote.category_id)
        allowedLineItemCategoriesIds.push(...(categoriesByParentIdNested[quote.category_id] || []))
    }

    const lineItemTypes = allLineItemTypes.filter(
        (item) =>
            allowedLineItemCategoriesIds.includes(item.category_id || 0) ||
            allowedLineItemTypesIds.includes(item.id)
    )

    const handleDragEnd = async (result: DropResult) => {
        if (!result.destination) return
        if (result.destination.droppableId === "line-items") {
            handleDragEndLineItems(result)
        }
    }

    const handleDragEndLineItems = async (result: DropResult) => {
        if (!result.destination) return
        const reorderedItems = [...lineItemsState]
        const [removed] = reorderedItems.splice(result.source.index, 1)
        reorderedItems.splice(result.destination.index, 0, removed)

        setLineItemsState(reorderedItems)

        const newOrder: { [key: number]: number } = {}
        reorderedItems.forEach((item, index) => {
            newOrder[item.id] = index
        })

        const response = await dispatch(reorderQuoteLineItems(quote.id, newOrder))
        if (response.status === 200) {
            enqueueSnackbar("Reordered successfully", { variant: "success" })
        }
    }

    const displayOptions: (1 | 2 | 3)[] = quote.data.print_template?.display_options || [1, 2]

    const onCreateLineItem = async (
        lineItemType: TLineItemType | null,
        section_id: number | null = null
    ) => {
        if (!quote.id || !lineItemType) return
        setIsSending(true)
        console.log("Service:", { lineItemType, section_id })
        let order = 0
        if (lineItems.length > 0) {
            order = Math.max(...lineItems.map((item) => item.order)) + 1
        }
        const response = await dispatch(
            createLineItem({
                quote_id: quote.id,
                type_id: lineItemType.id,
                order,
                section_id,
            })
        )
        if (response.status === 201) {
            setIsSending(false)
            setLineItemTypeToCreate(null)
            enqueueSnackbar(`Service ${lineItemType.name} created`, { variant: "success" })
        } else {
            setIsSending(false)
            enqueueSnackbar(`Error: Server response ${response.status}`, { variant: "error" })
        }
    }

    if (displayOnlyCompletedItems) {
        const completedItems = lineItems.filter((item) =>
            LineItemService.hasPrice(item, materialsByLineItemId[item.id] || [])
        )
        return (
            <Stack direction="column" spacing={0}>
                {completedItems.map((item) => (
                    <LineItemDetails
                        key={item.id}
                        lineItem={item}
                        displayOptions={displayOptions}
                        expanded={expandedLineItemId === item.id}
                        setExpanded={(value) => {
                            setExpandedLineItemId(value)
                        }}
                        materials={materialsByLineItemId[item.id] || []}
                        measurements={measurementsByLineItemId[item.id] || []}
                    />
                ))}
            </Stack>
        )
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Stack direction="column" spacing={0}>
                {sections.map((section) => (
                    <QuoteSectionDetails
                        key={section.id}
                        // lineItemTypes={lineItemTypes}
                        section={section}
                        displayOptions={displayOptions}
                        lineItems={lineItemsBySections[section.id] || []}
                        expandedLineItemId={expandedLineItemId}
                        setExpandedLineItemId={(value) => {
                            setExpandedLineItemId(value)
                        }}
                        // onCreateLineItem={onCreateLineItem}
                        measurementsByLineItemId={measurementsByLineItemId}
                        materialsByLineItemId={materialsByLineItemId}
                    />
                ))}

                <Droppable droppableId="line-items">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {lineItemsState.map((item, index) => {
                                if (item.section_id !== null) return null
                                return (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id.toString()}
                                        index={index}
                                    >
                                        {(pr) => (
                                            <div
                                                ref={pr.innerRef}
                                                {...pr.draggableProps}
                                                {...pr.dragHandleProps}
                                            >
                                                <LineItemDetails
                                                    key={item.id}
                                                    lineItem={item}
                                                    displayOptions={displayOptions}
                                                    expanded={expandedLineItemId === item.id}
                                                    setExpanded={(value) => {
                                                        // setExpandedLineItemId(null)
                                                        setExpandedLineItemId(value)
                                                    }}
                                                    materials={materialsByLineItemId[item.id] || []}
                                                    measurements={
                                                        measurementsByLineItemId[item.id] || []
                                                    }
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={1}
                    alignItems="center"
                    sx={{ mb: 0, mt: 2 }}
                >
                    <Autocomplete
                        id="possible-line-items"
                        getOptionLabel={(option) => getLineItemName(option)}
                        onChange={async (event: any, value: TLineItemType | null) => {
                            setLineItemTypeToCreate(value)
                            onCreateLineItem(value)
                        }}
                        value={lineItemTypeToCreate}
                        disabled={isSending}
                        options={lineItemTypes}
                        sx={{ width: "100%" }}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                // size="small"
                                label="Add a Service"
                                InputProps={{
                                    ...params.InputProps,
                                }}
                            />
                        )}
                    />
                </Stack>
            </Stack>
        </DragDropContext>
    )
}

export default QuoteContentItems
