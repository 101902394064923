import { Button, ButtonGroup, Container, Stack } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "react-router"
import CircularProgress from "src/components/circular-progress"
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs"
import { getQuote } from "src/redux/slices/quotes"
import { useDispatch, useSelector } from "src/redux/store"
import { PATHS } from "src/routes/paths"
import "../quote-view/base.css"

import ReactToPrint from "react-to-print"
import QuoteTermsAndConditions from "../quote-view/terms-and-conditions"
import QuoteJHA from "../quote-view/jha"
import QuoteTables from "../quote-view/quote"

export default function QuotePrintTwoPage() {
    const [isLoading, setIsLoading] = useState(true)
    const { ids } = useParams<{ ids: string }>()

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const componentRef = useRef<any>(null)

    // @ts-ignore
    const idsAsNumber = ids.split(",").map((id) => parseInt(id, 10))

    const id1 = idsAsNumber[0]
    const id2 = idsAsNumber[1]

    useEffect(() => {
        const getQuoteAndLineItemsAsync = async () => {
            if (id1 && id2) {
                await dispatch(getQuote(id1))
                await dispatch(getQuote(id2))
            }
            setIsLoading(false)
        }
        getQuoteAndLineItemsAsync()
    }, [dispatch, id1, id2])

    const quote1 = useSelector((state) =>
        state.quotes.quotes.find((item) => item.id === idsAsNumber[0])
    )
    const quote2 = useSelector((state) =>
        state.quotes.quotes.find((item) => item.id === idsAsNumber[1])
    )

    if (idsAsNumber.length !== 2) {
        return <div>Provide two quote ids</div>
    }

    if (isLoading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        )
    }

    if (quote1 === undefined || quote2 === undefined) {
        return <div>Not found</div>
    }

    return (
        <>
            <Helmet>
                <title>{`Quotes ${quote1?.sf_estimate?.number || ""} & ${
                    quote2?.sf_estimate?.number || ""
                }`}</title>
            </Helmet>
            <Container className="printable-fullwidth">
                <CustomBreadcrumbs
                    heading="View Quote"
                    links={[
                        {
                            name: "Quotes",
                            href: PATHS.quotes.quotes.list(),
                        },
                        {
                            name: "View",
                        },
                    ]}
                    action={
                        <ButtonGroup variant="contained" size="small">
                            <Button
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                Back
                            </Button>
                            <ReactToPrint
                                trigger={() => <Button variant="outlined">Print</Button>}
                                content={() => componentRef.current}
                            />
                        </ButtonGroup>
                    }
                />
                <div ref={componentRef}>
                    <QuoteTables quote={quote1} />
                    <Stack
                        className={quote1.data.print_template?.disable_jha ? "toc no-break" : "toc"}
                    >
                        <QuoteTermsAndConditions quote={quote1} />
                    </Stack>
                    <QuoteTables quote={quote2} />
                    <Stack
                        className={quote2.data.print_template?.disable_jha ? "toc no-break" : "toc"}
                    >
                        <QuoteTermsAndConditions quote={quote2} />
                    </Stack>
                    {!quote1.data.print_template?.disable_jha && (
                        <Stack>
                            <QuoteJHA quote={quote1} />
                        </Stack>
                    )}
                    {!quote2.data.print_template?.disable_jha && (
                        <Stack>
                            <QuoteJHA quote={quote2} />
                        </Stack>
                    )}
                </div>
                {/* </Stack> */}
            </Container>
        </>
    )
}
